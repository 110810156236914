.root {
    margin: 28px auto 0;    
}

.buttonSkeleton,
.button {
    width: 52px;
    height: 52px;
}

.buttonSkeleton {
    margin: var(--spacing-core-2) 6px;
}

.pageSummary {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--spacing-core-4);
}
