.root {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 52px;
    margin: var(--spacing-core-2) 6px;
    color: var(--color-background-quaternary-default);
}
