.root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    margin: var(--spacing-core-2) 6px;
    border: 2px solid var(--color-background-secondary-hover);
    border-radius: var(--border-radius-core-s);
    background: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
        border-color: var(--color-background-secondary-hover);
        background: var(--color-background-secondary-hover);
        outline: 0;
    }
}

.text {
    font-weight: 800;
}

.current {
    border-color: var(--color-action-secondary-default);
}
